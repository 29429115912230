<template>

  <el-container style="height:calc(93.5vh);">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      预约时间
    </el-header>
    <el-container>
      <el-container style="width:75%; height:calc(93.5vh);">
        <el-aside class="aside" width="120px">
          <div class="time el-icon-date">
            预约日期
          </div>
          <el-menu class="menu">
            <el-menu-item v-for=" (item,i) in item" :key="i">
              <template slot="title">
                <div :class="activeItem===item?'nav1':'nav'" @click="activeItem=item">
                  <span>{{item}}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main class="main">
            <div class="alert">
              <div class="left el-icon-warning">合理的排班会让工作更便捷和高效</div>
              <router-link to="/scheduling" class="right">排班管理</router-link>
            </div>
            <div class="body">
              <div class="card" v-for="(item ,i) in 3" :key="i">
                <div class="title">上午</div>
                <div class="box">
                  <div v-for="(item1 ,i1) in 8" :key="i1">
                    <div v-if="i1<4" class="time-box time-box1" @click="msgShow">08:00</div>
                    <div v-else :class="aboutTime===i1?'time-box time-box2':'time-box'" @click="addAbout(i1,'08:00')">
                      08:00
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  import {
    mapMutations
  } from 'vuex'
  export default {
    data() {
      return {
        item: ['今天 周一', '明天 周二', '2/1 周三', ' 2/2 周四', '2/3 周五', '2/4 周六', '2/5 周日'],
        activeItem: '今天 周一',
        aboutTime: null,
      }
    },
    methods: {
      ...mapMutations(['updataeAboutTime']),
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      msgShow() {
        this.$message('此时段已有预约或未排班请预约其他时间段');
      },
      addAbout(i, time) {
        this.aboutTime = i
        this.updataeAboutTime(time)
        this.$router.go(-1)
      }

    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  // 左侧导航
  /deep/ .aside.el-aside {
    min-height: calc(93.5vh);
    background-color: #fff;

    &:first-child {
      padding-top: 30px;
    }

    .time.el-icon-date {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      color: #666;
      font-size: 12px;

      &::before {
        margin-right: 5px;
      }
    }

    .menu {
      background-color: #fff;

      .el-menu-item:focus,
      .el-menu-item:hover {
        background-color: #fff;
      }
    }

    .nav {
      text-align: center;

      span {
        border: 1px solid transparent;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }

    .nav1 {
      text-align: center;

      span {
        color: #409eff;
        border: 1px solid #409eff;
        padding: 5px 15px;
        border-radius: 50px;
      }
    }
  }

  // 左侧商品卡片
  .main {
    background-color: #f9f9f9;

    .alert {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff5e6;
      color: #ff9900;
      padding: 0 10px;
      margin: 15px 0;
      font-size: 12px;
      border: 1px solid #ffebcc;

      .left {
        line-height: 40px;

        &::before {
          margin-right: 5px;
        }
      }

      .right {
        border: 1px solid #ff9900;
        color: #ff9900;
        line-height: 25px;
        padding: 0 15px;
        border-radius: 5px;
      }
    }

    .card {
      background-color: #fff;
      box-shadow: 0 0 2px #ccc;
      margin-bottom: 15px;
      padding: 10px;

      .title {
        margin-bottom: 10px;
        color: #666;
        font-weight: bold;
      }

      .box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .time-box {
          text-align: center;
          line-height: 30px;
          width: 120px;
          font-size: 12px;
          color: #666;
          border: 1px solid #f1f1f1;
          border-radius: 50px;
          margin: 10px;
        }

        .time-box1 {
          background-color: #f2f2f2;
          color: #ccc;
        }

        .time-box2 {
          border: 1px solid #409eff;
          background-color: #409eff;
          color: #fff;
        }
      }

    }
  }

  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  .main.el-main {
    padding: 0 10px !important;
  }

</style>
